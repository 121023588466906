<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    bottom
    left
    max-width="90%"
    min-width="275"
    offset-x
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-card
        id="settings"
        class="py-2 px-4"
        color="#0000004D"
        dark
        flat
        style="position: fixed; top: 164px; right: -5px;"
        width="66"
        v-bind="attrs"
      >
        <v-img
          v-if="language === '/de'"
          :src="require('@/assets/flag_en.png')"
          @click="changeLanguage('/en')"
        />
        <v-img
          v-if="language === '/en'"
          :src="require('@/assets/flag_de.png')"
          @click="changeLanguage('/de')"
        />

      </v-card>
    </template>

    <v-card class="py-2">
      <base-title
        align="center"
        title="Theme Colors"
        space="0"
      />

      <v-card-text>
        <v-item-group
          v-model="currentThemePrimary"
          class="d-flex justify-center"
        >
          <v-item
            v-for="color in colors"
            :key="color"
            :value="color"
          >
            <template v-slot="{ active, toggle }">
              <base-avatar
                :color="color"
                :outlined="active"
                class="ma-2"
                size="24"
                style="cursor: pointer;"
                @click.stop="toggle"
              />
            </template>
          </v-item>
        </v-item-group>

        <v-divider class="my-6" />

        <base-btn
          class="mr-4"
          :outlined="$vuetify.theme.dark"
          @click="$vuetify.theme.dark = false"
        >
          <v-icon left>
            mdi-white-balance-sunny
          </v-icon>
          Light
        </base-btn>

        <base-btn
          :outlined="!$vuetify.theme.dark"
          @click="$vuetify.theme.dark = true"
        >
          <v-icon left>
            mdi-weather-night
          </v-icon>
          Dark
        </base-btn>

      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    name: 'HomeSettings',

    data () {
      return {
        colors: [
          this.$vuetify.theme.currentTheme.primary,
          '#9368e9',
          '#F4511E',
          '#000654',
          '#FFFFFF',
          '#000000',
          '#FF0000',
          '#800000',
          '#FFFF00',
          '#808000',
          '#00FF00',
          '#008000',
          '#00FFFF',
          '#008080',
          '#0000FF',
          '#000080',
          '#FF00FF',
          '#800080',
        ],
        menu: false,
        language: '/de',
      }
    },

    computed: {
      currentThemePrimary: {
        get () {
          return this.$vuetify.theme.currentTheme.primary
        },
        set (val) {
          const target = this.$vuetify.theme.isDark ? 'dark' : 'light'

          this.$vuetify.theme.themes[target].primary = val
        },
      },
    },

    created () {
      this.language = this.$route.path
      if (this.$route.path === '/') {
        this.language = '/de'
      }
    },

    methods: {
      changeLanguage (val) {
        // console.log('inSettings:', this.$route.path)
        this.$router.push({ path: val })
        this.language = val
      },
    },
  }
</script>
